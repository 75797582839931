const colours = [
  'gray',
  'yellow',
  'orange',
  'red',
  'green',
  'teal',
  'blue',
  'indigo',
  'purple',
  'pink',
];

const getColour = () => {
  return colours[Math.floor(Math.random() * colours.length)]
}

export default getColour;