
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';

const Session = (props) => {
  const [connection, setConnection] = useState(null);
  const [name, setName] = useState('');
  const [colour, setColour] = useState('');
  const [observer, setObserver] = useState(null);
  const [card, setCard] = useState(null);
  const [session, setSession] = useState(null);
  const [linkCopied, setLinkCopied] = useState(false);
  const sessionRef = useRef(null);
  const observerRef = useRef(false);
  const history = useHistory();

  useEffect(
    () => {
      let timer1 = setTimeout(() => setLinkCopied(false), 2000);

      return () => {
        clearTimeout(timer1);
      };
    },
    [linkCopied]
  );

  useEffect(() => {
    sessionRef.current = session;
    observerRef.current = observer;

    if (session && observer === true && session.votingActive) {
      submitVote(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, observer]);

  useEffect(() => {
    if (observerRef.current !== null) {
      window.localStorage.setItem('observer', observer);
    }
  }, [observer]);

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl('/estimationhub')
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);

    const initName = window.localStorage.getItem('name');
    setName(initName);
    setColour(window.localStorage.getItem('colour'));
    setObserver(window.localStorage.getItem('observer') === 'true');

    const userId = Cookies.get('UserId');

    if (!userId || !initName) {
      history.push(`/join/${props.match.params.id}`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (connection) {
      connection.start()
        .then(() => {
          connection.on('StartVote', () => {
            setSession(prevState => {
              return {
                ...prevState,
                votingActive: true,
                hasVoted: observerRef.current,
                votes: [],
                voteResult: null,
              }
            });
          });

          connection.on('EndVote', voteResult => {
            setCard(null);
            setSession(prevState => {
              return {
                ...prevState,
                votingActive: false,
                voteResult: voteResult,
              }
            });
          });

          connection.on('ClearVotes', () => {
            setSession(prevState => {
              return {
                ...prevState,
                voteResult: null,
                votingActive: false,
                hasVoted: false,
                votes: [],
              }
            });
          });

          connection.on('VoteSubmitted', vote => {
            setSession(prevState => {
              return {
                ...prevState,
                votes: [...prevState.votes, vote],
              }
            });
          });

          connection.on('HasVoted', () => {
            setSession(prevState => {
              return {
                ...prevState,
                hasVoted: true,
              }
            });
          });

          connection.on('UserList', users => {
            setSession(prevState => {
              return {
                ...prevState,
                users: users,
              }
            });
          });

          /*connection.on('StatusUpdate', message => {
            console.log('StatusUpdate', message);
          });*/

          connection.on('Session', sessionState => {
            setSession(sessionState);
          });

          connection.on('SetName', name => {
            setName(name);
            window.localStorage.setItem('name', name);
          });

          connection.send('AddToSession', props.match.params.id, name, colour);

          // connection.onreconnecting(error => {
          //   console.log('onreconnecting', error);
          // });

          connection.onreconnected(connectionId => {
            connection.send('AddToSession', props.match.params.id, name, colour);
          });
        })
        .catch(e => console.log('Connection failed: ', e));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (connection.connectionStarted) {
        connection.send('PollClientResponse', Date.now());
      }
    }, 15000);
  
    return () => {
      clearInterval(interval);
    };
  }, [connection]);


  const resetConnection = () => {
    const newConnection = new HubConnectionBuilder()
      .withUrl('/estimationhub')
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
  }

  const startVote = async () => {
    if (connection.connectionStarted) {
      try {
        await connection.send('StartVote', session.id);
      }
      catch (e) {
        console.log(e);
      }
    }
    else {
      resetConnection();
      await startVote();
    }
  }

  const endVote = async () => {
    if (connection.connectionStarted) {
      try {
        await connection.send('EndVote', session.id);
      }
      catch (e) {
        console.log(e);
      }
    }
    else {
      resetConnection();
      await endVote();
    }
  }

  const clearVotes = async () => {
    if (connection.connectionStarted) {
      try {
        await connection.send('ClearVotes', session.id);
      }
      catch (e) {
        console.log(e);
      }
    }
    else {
      resetConnection();
      await clearVotes();
    }
  }

  const submitVote = async (skip) => {
    if (connection.connectionStarted) {
      const vote = {
        sessionId: props.match.params.id,
        name: name,
        card: card,
        skip,
      };

      try {
        await connection.send('SubmitVote', vote);
      }
      catch (e) {
        console.log(e);
      }
    }
    else {
      resetConnection();
      await submitVote(skip);
    }
  }

  return (
    <>
      <Helmet>
        <title>{`${session ? `Estimation Poker | ${session.name}` : 'Estimation Poker'}`}</title>
      </Helmet>
      <div>
        <div className="relative bg-white h-screen">
          <nav className="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-no-wrap md:justify-start flex items-center p-3 md:p-4">
            <div className="w-full mx-auto items-center flex justify-between md:flex-no-wrap flex-wrap">
              <a className="text-white text-base uppercase lg:inline-block" href="/">
                Estimation Poker
              </a>
            </div>
            <a
              className="hidden md:block w-44 xl:w-40 mx-auto bg-brilliant-pink hover:bg-brilliant-pink-600 focus:bg-brilliant-pink-600 text-white rounded font-bold px-2 pb-1 pt-0 top-1 relative"
              href="https://ko-fi.com/V7V03TS2L"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="inline w-5 animate-wiggle"
                src="/cup-border.webp"
                alt="Buy Me a Coffee at ko-fi.com"
              />
              <span className="inline ml-2 text-xs">Buy me a coffee</span>
            </a>
            {/* <div className="flex text-brilliant-pink font-bold">
              <svg className="flex w-6 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <span className="flex">
                1m
              </span>
            </div> */}
          </nav>
          <div className="relative bg-deep-purple md:pt-32 md:pb-32 pt-12"></div>
          <div className="mx-auto w-full">
            <div className="flex flex-wrap">
              <div className="w-full md:w-4/12 lg:w-3/12 md:-mt-28 md:min-h-left-panel md:overflow-hidden">
                <div className="fixed bottom-20 md:bottom-0 z-20 w-full bg-deep-purple md:relative flex flex-row min-w-0 break-words justify-center m-auto md:bg-white md:bg-opacity-10">
                  {
                    session && (
                      <label className="inline-flex items-center mt-3 mb-2 md:mb-0">
                        <input type="checkbox" className="h-5 w-5 text-brilliant-pink border-2 border-gray-200 rounded-md focus:outline-none focus:ring-0 focus:ring-transparent" checked={observer === null ? false : observer} onChange={() => setObserver(!observer)} />
                        <span className="ml-2 uppercase text-white text-xs font-semibold">I'm an observer</span>
                      </label>
                    )
                  }
                </div>
                <div className="fixed bottom-0 z-20 w-full bg-deep-purple md:relative flex flex-row min-w-0 break-words px-4 m-auto md:bg-white md:bg-opacity-10">
                  {
                    session && (
                      <div className="w-6/12 px-3 m-auto">
                        {
                          session && !session.votingActive && session.voteResult === null && (
                            <button
                              onClick={startVote}
                              className="block w-full max-w-xs mx-auto bg-brilliant-pink hover:bg-brilliant-pink-600 focus:bg-brilliant-pink-600 text-white rounded-lg px-2 py-2 md:px-1 md:py-1 lg:px-2 lg:py-2 font-semibold my-5 truncate"
                            >
                              Start Vote
                            </button>
                          )
                        }
                        {
                          session && session.votingActive && (
                            <button
                              onClick={endVote}
                              className="block w-full max-w-xs mx-auto bg-brilliant-pink hover:bg-brilliant-pink-600 focus:bg-brilliant-pink-600 text-white rounded-lg px-2 py-2 md:px-1 md:py-1 lg:px-2 lg:py-2 font-semibold my-5 truncate"
                            >
                              End Vote
                            </button>
                          )
                        }
                        {
                          session && !session.votingActive && session.voteResult && (
                            <button
                              onClick={clearVotes}
                              className="block w-full max-w-xs mx-auto bg-brilliant-pink hover:bg-brilliant-pink-600 focus:bg-brilliant-pink-600 text-white rounded-lg px-2 py-2 md:px-1 md:py-1 lg:px-2 lg:py-2 font-semibold my-5 truncate"
                            >
                              Reset
                            </button>
                          )
                        }
                      </div>
                    )
                  }
                  {
                    session && (
                      <div className="w-6/12 px-3 m-auto">
                        <button
                          onClick={() => submitVote(false)}
                          disabled={card === null || session.hasVoted || session.votingActive === false}
                          className={`block w-full max-w-xs mx-auto bg-brilliant-pink ${card === null || session.hasVoted || session.votingActive === false ? '' : 'hover:bg-brilliant-pink-600 focus:bg-brilliant-pink-600 animate-single-pulse'} text-white rounded-lg px-2 py-2 md:px-1 md:py-1 lg:px-2 lg:py-2 font-semibold my-5 disabled:opacity-50 truncate`}
                        >
                          Submit Vote
                        </button>
                      </div>
                    )
                  }
                </div>

                <div className="relative flex flex-col min-w-0 break-words bg-gray-50 md:w-full md:h-full">
                  <div className="mb-0 px-4 pt-6 pb-3 bg-transparent">
                    <div className="relative w-full pl-3 2xl:w-3/4 md:px-3 mb-5 mx-auto">
                      <label htmlFor="share-link" className="uppercase text-gray-500 mb-3 text-xs font-semibold">Share session with teammates</label>
                      <div className={`flex ${linkCopied ? 'opacity-10' : ''}`}>
                        <div className="w-5 z-10 text-center pointer-events-none flex items-center justify-center text-gray-400">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                          </svg>
                        </div>
                        {
                          session && (
                            <input
                              onClick={() => {
                                navigator.clipboard.writeText(`https://${window.location.host}/join/${session.id}`);
                                setLinkCopied(true);
                              }}
                              id="share-link"
                              type="text"
                              readOnly
                              defaultValue={`https://${window.location.host}/join/${session.id}`}
                              className="w-full -ml-7 pl-7 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:ring-0 focus:border-brilliant-pink cursor-pointer"
                            />
                          )
                        }
                      </div>
                      {
                        linkCopied && (
                          <div className="absolute top-0 left-0 w-full h-full z-20">
                            <div
                              className="animate-bounce uppercase text-green-700 mt-10 text-xs font-semibold text-center"
                            >
                              Copied!
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </div>

                  <div className="w-full pl-4 2xl:w-3/4 md:px-3 md:mb-5 mx-auto">
                    <div className="mb-0 px-4 pb-2 bg-transparent">
                      <div className="flex flex-wrap items-center">
                        <div className="relative w-full max-w-full flex-grow flex-1">
                          <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold">Users</h6>
                        </div>
                      </div>
                    </div>
                    <div className="p-1 flex-auto">
                      <div className="relative">
                        {
                          session && session.users.filter(x => x.isOwner === true).map(user => (
                            <div
                              key={user.id}
                              className={`inline-block mb-3 rounded-full bg-${user.colour}-100 pr-5 h-12 mr-5 overflow-hidden`}
                            >
                              <span
                                className={`inline-flex items-center justify-center uppercase text-white font-medium shadow bg-${user.colour}-400 rounded-full h-12 w-12`}
                              >
                                <span>{user.name ? user.name.charAt(0) : '?'}</span>
                              </span>
                              <span className="inline-block ml-3 text-gray-600 capitalize">{user.name}</span>
                            </div>
                          ))
                        }
                        {
                          session && session.users
                            .sort(function (a, b) {
                              let textA = a.name.toUpperCase();
                              let textB = b.name.toUpperCase();

                              return textA.localeCompare(textB);
                            })
                            .filter(x => x.isOwner === false).map(user => (
                              <div
                                key={user.id}
                                className={`inline-block mb-3 rounded-full bg-${user.colour}-100 pr-5 h-12 mr-5 overflow-hidden`}
                              >
                                <span
                                  className={`inline-flex items-center justify-center uppercase text-white font-medium shadow bg-${user.colour}-400 rounded-full h-12 w-12`}
                                >
                                  <span>{user.name ? user.name.charAt(0) : '?'}</span>
                                </span>
                                <span className="inline-block ml-3 text-gray-600 capitalize">{user.name}</span>
                              </div>
                            ))
                        }
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="relative w-full min-h-right-panel-mob pb-32 md:pb-0 md:w-8/12 lg:w-9/12 md:-mt-48 md:shadow-lg bg-white md:min-h-right-panel md:overflow-hidden">
                <div className="relative mb-0 px-4 py-1 w-full">
                  <div className="flex flex-wrap items-center">
                    <div className="relative max-w-full flex-grow flex py-2">
                      <h6 className="uppercase text-gray-700 mb-1 text-base font-light">
                        {session && session.name}
                        {
                          session && session.votingActive && (
                            <span> - Voting</span>
                          )
                        }
                        {
                          session && session.voteResult && (
                            <span> - Results</span>
                          )
                        }
                      </h6>
                    </div>
                    <div className="relative max-w-full flex-grow flex justify-end">
                      {
                        session && session.hasVoted === false && session.votingActive && (
                          <button
                            onClick={() => submitVote(true)}
                            className={`block w-20 p-2 text-xs bg-gray-100 hover:bg-gray-200 focus:bg-gray-200 text-gray-500 rounded`}
                          >
                            Skip Vote
                          </button>
                        )
                      }
                    </div>
                  </div>
                </div>
                <div className="relative flex flex-col min-w-0 break-words w-full h-full justify-center">

                  <div className="p-4 flex">
                    <div className="relative text-center w-full -mt-12">
                      {
                        session && session.votes.length === 0 && session.hasVoted === false && session.voteResult === null && session.votingActive === false && (
                          <div>
                            <img
                              src="/Waiting_Isometric.svg"
                              alt="Waiting"
                              className="w-6/12 m-auto"
                            />
                            <p className="p-6 font-light text-gray-400">Waiting for voting to start</p>
                          </div>
                        )
                      }
                      {
                        session && session.hasVoted === false && session.votingActive && session.deck.map(c => (
                          <div
                            onClick={
                              () => card === null || card !== c.name ? setCard(c.name) : setCard(null)
                            }
                            key={c.name}
                            className={`inline-flex align-top mx-4 mb-8 bg-white rounded-lg shadow-lg border border-transparent ${c.name === card ? 'border-brilliant-pink' : ''}`}
                          >
                            <div className="h-40 w-32 border-t-8 border-brilliant-pink rounded-lg flex justify-items items-center cursor-pointer">
                              <div className="w-full">
                                {
                                  c.name === 'Coffee' && (
                                    <div className="m-10 text-brilliant-pink">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140" fill="currentColor">
                                        <path d="M117.199 127.142H13.731a2.098 2.098 0 00-2.068 1.795 2.09 2.09 0 001.483 2.303L42.703 140a1.97 1.97 0 00.585.098h44.345c.195 0 .4-.039.586-.098l29.557-8.76a2.09 2.09 0 001.482-2.303 2.082 2.082 0 00-2.059-1.795zm-11.813-66.98l.653-11.457a2.06 2.06 0 00-.565-1.561 2.087 2.087 0 00-1.521-.659H26.978a2.11 2.11 0 00-1.521.649 2.1 2.1 0 00-.566 1.561l4.185 73.198a2.08 2.08 0 002.087 1.971h68.614a2.08 2.08 0 002.088-1.971l.673-11.695 1.425.068c13.51 0 24.493-11.257 24.493-25.089 0-13.339-10.233-24.254-23.07-25.015zm-66.068 56.387h-.205a3.124 3.124 0 01-3.122-2.946l-3.492-56.47a3.156 3.156 0 012.946-3.321c1.795-.132 3.219 1.205 3.326 2.936l3.492 56.464c.098 1.737-1.219 3.23-2.945 3.337zm64.635-14.632c-.322 0-.625-.078-.947-.098l1.893-33.269c8.448.521 15.188 7.745 15.188 16.627-.001 9.229-7.239 16.74-16.134 16.74zM40.361 29.44c1.405 6.35 8.262 9.345 9.042 9.667l2.39-5.805c-1.19-.487-4.711-2.497-5.307-5.214-.41-1.858.566-4.087 2.907-6.604 3.854-4.175 5.346-8.277 4.419-12.228C52.36 3.058 45.57.288 44.8 0l-2.244 5.853c.039.02 4.438 1.829 5.141 4.823.517 2.239-1.297 4.785-2.917 6.546-3.804 4.126-5.297 8.228-4.419 12.218zm16.729 0c1.405 6.35 8.263 9.345 9.043 9.667l2.39-5.805c-1.18-.487-4.692-2.487-5.297-5.214-.419-1.858.566-4.087 2.897-6.604 3.853-4.175 5.346-8.277 4.419-12.228C69.089 3.058 62.299.288 61.519 0l-2.233 5.853c.039.02 4.438 1.829 5.141 4.823.517 2.239-1.298 4.785-2.917 6.546-3.805 4.126-5.298 8.228-4.42 12.218zm16.74 0c1.395 6.35 8.252 9.345 9.033 9.667l2.39-5.805c-1.181-.487-4.692-2.487-5.297-5.214-.419-1.858.565-4.087 2.897-6.604 3.862-4.175 5.346-8.277 4.419-12.228C85.818 3.058 79.019.288 78.249 0l-2.233 5.853c.039.02 4.447 1.829 5.141 4.823.526 2.239-1.298 4.785-2.917 6.546-3.805 4.126-5.288 8.228-4.41 12.218z"></path>
                                      </svg>
                                    </div>
                                  )
                                }
                                {
                                  c.name !== 'Coffee' && (
                                    <h3 className="text-xl font-bold text-brilliant-pink">{c.value !== null ? c.value.toString().replace('0.5', '½') : c.name}</h3>
                                  )
                                }
                              </div>
                            </div>
                          </div>
                        ))
                      }
                      {
                        session && session.votes.length === 0 && session.hasVoted === true && session.votingActive === true && (
                          <div>
                            <div
                              className={`inline-flex align-top mx-4 mb-8 bg-white rounded-lg shadow-lg`}
                            >
                              <div className="animate-pulse h-40 w-32 border-t-8 border-brilliant-pink rounded-lg flex justify-items items-center">
                                <div className="w-full">
                                  <h3 className="text-xl font-bold text-brilliant-pink">-</h3>
                                  <p className="text-gray-400">
                                    <svg
                                      className="w-full h-10"
                                      xmlns="http://www.w3.org/2000/svg"
                                      x="0"
                                      y="0"
                                      version="1.1"
                                      viewBox="0 0 53 100"
                                      xmlSpace="preserve"
                                    >
                                      <circle cx="6" cy="50" r="6" fill="currentColor">
                                        <animate
                                          attributeName="opacity"
                                          begin="0.1"
                                          dur="1s"
                                          repeatCount="indefinite"
                                          values="0;1;0"
                                        ></animate>
                                      </circle>
                                      <circle cx="26" cy="50" r="6" fill="currentColor">
                                        <animate
                                          attributeName="opacity"
                                          begin="0.2"
                                          dur="1s"
                                          repeatCount="indefinite"
                                          values="0;1;0"
                                        ></animate>
                                      </circle>
                                      <circle cx="46" cy="50" r="6" fill="currentColor">
                                        <animate
                                          attributeName="opacity"
                                          begin="0.3"
                                          dur="1s"
                                          repeatCount="indefinite"
                                          values="0;1;0"
                                        ></animate>
                                      </circle>
                                    </svg>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <p className="font-light text-gray-400">Waiting for votes</p>
                          </div>
                        )
                      }
                      {
                        session && session.votes.length > 0 && session.voteResult && session.voteResult.average > 0 && (
                          <div>
                            <div
                              className={`inline-flex align-top mx-4 mb-8 bg-white rounded-lg shadow-lg`}
                            >
                              <div className="h-40 w-32 border-t-8 border-brilliant-pink rounded-lg flex justify-items items-center">
                                <div className="w-full">
                                  <h3 className="text-xl font-bold text-brilliant-pink">
                                    {
                                      Math.round((session.voteResult.average + Number.EPSILON) * 100) / 100
                                    }
                                  </h3>
                                  <p className="py-4 capitalize text-sm text-gray-400">
                                    Average
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              className={`inline-flex align-top mx-4 mb-8 bg-white rounded-lg shadow-lg`}
                            >
                              <div className="h-40 w-32 border-t-8 border-brilliant-pink rounded-lg flex justify-items items-center">
                                <div className="w-full">
                                  <h3 className="text-xl font-bold text-brilliant-pink">
                                    {
                                      session.voteResult.nearest.toString().replace('0.5', '½')
                                    }
                                  </h3>
                                  <p className="py-4 capitalize text-sm text-gray-400">
                                    Nearest
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                      {
                        session && session.votes.length > 0 && (session.hasVoted === true || session.voteResult) && (
                          <div>
                            {
                              session.votes.map(vote => (
                                <div
                                  key={vote.id}
                                  className={`inline-flex align-top mx-4 mb-8 bg-white rounded-lg shadow-lg`}
                                >
                                  <div className="h-40 w-32 border-t-8 border-brilliant-pink rounded-lg flex justify-items items-center">
                                    <div className="w-full">
                                      {
                                        session.votingActive && vote.card.name === 'Coffee' && (
                                          <h3 className="text-xl font-bold text-brilliant-pink">-</h3>
                                        )
                                      }
                                      {
                                        session.votingActive === false && vote.card.name === 'Coffee' && (
                                          <div className="w-10 mx-auto -mt-6 text-brilliant-pink">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140" fill="currentColor">
                                              <path d="M117.199 127.142H13.731a2.098 2.098 0 00-2.068 1.795 2.09 2.09 0 001.483 2.303L42.703 140a1.97 1.97 0 00.585.098h44.345c.195 0 .4-.039.586-.098l29.557-8.76a2.09 2.09 0 001.482-2.303 2.082 2.082 0 00-2.059-1.795zm-11.813-66.98l.653-11.457a2.06 2.06 0 00-.565-1.561 2.087 2.087 0 00-1.521-.659H26.978a2.11 2.11 0 00-1.521.649 2.1 2.1 0 00-.566 1.561l4.185 73.198a2.08 2.08 0 002.087 1.971h68.614a2.08 2.08 0 002.088-1.971l.673-11.695 1.425.068c13.51 0 24.493-11.257 24.493-25.089 0-13.339-10.233-24.254-23.07-25.015zm-66.068 56.387h-.205a3.124 3.124 0 01-3.122-2.946l-3.492-56.47a3.156 3.156 0 012.946-3.321c1.795-.132 3.219 1.205 3.326 2.936l3.492 56.464c.098 1.737-1.219 3.23-2.945 3.337zm64.635-14.632c-.322 0-.625-.078-.947-.098l1.893-33.269c8.448.521 15.188 7.745 15.188 16.627-.001 9.229-7.239 16.74-16.134 16.74zM40.361 29.44c1.405 6.35 8.262 9.345 9.042 9.667l2.39-5.805c-1.19-.487-4.711-2.497-5.307-5.214-.41-1.858.566-4.087 2.907-6.604 3.854-4.175 5.346-8.277 4.419-12.228C52.36 3.058 45.57.288 44.8 0l-2.244 5.853c.039.02 4.438 1.829 5.141 4.823.517 2.239-1.297 4.785-2.917 6.546-3.804 4.126-5.297 8.228-4.419 12.218zm16.729 0c1.405 6.35 8.263 9.345 9.043 9.667l2.39-5.805c-1.18-.487-4.692-2.487-5.297-5.214-.419-1.858.566-4.087 2.897-6.604 3.853-4.175 5.346-8.277 4.419-12.228C69.089 3.058 62.299.288 61.519 0l-2.233 5.853c.039.02 4.438 1.829 5.141 4.823.517 2.239-1.298 4.785-2.917 6.546-3.805 4.126-5.298 8.228-4.42 12.218zm16.74 0c1.395 6.35 8.252 9.345 9.033 9.667l2.39-5.805c-1.181-.487-4.692-2.487-5.297-5.214-.419-1.858.565-4.087 2.897-6.604 3.862-4.175 5.346-8.277 4.419-12.228C85.818 3.058 79.019.288 78.249 0l-2.233 5.853c.039.02 4.447 1.829 5.141 4.823.526 2.239-1.298 4.785-2.917 6.546-3.805 4.126-5.288 8.228-4.41 12.218z"></path>
                                            </svg>
                                          </div>
                                        )
                                      }
                                      {
                                        vote.card.name !== 'Coffee' && (
                                          <h3 className="text-xl font-bold text-brilliant-pink">
                                            {
                                              session.votingActive ? '-' : vote.card.value !== null ? vote.card.value.toString().replace('0.5', '½') : vote.card.name
                                            }
                                          </h3>
                                        )
                                      }
                                      <p className={`${vote.card.name !== 'Coffee' ? 'py-4 capitalize text-sm text-gray-400' : 'pt-5 capitalize text-sm text-gray-400'}`}>
                                        {
                                          vote.name
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))
                            }
                            {
                              session && session.voteResult === null && (
                                <div
                                  className={`inline-flex align-top mx-4 mb-8 bg-white rounded-lg shadow-lg`}
                                >
                                  <div className="animate-pulse h-40 w-32 border-t-8 border-brilliant-pink rounded-lg flex justify-items items-center">
                                    <div className="w-full">
                                      <h3 className="text-xl font-bold text-brilliant-pink">-</h3>
                                      <p className="text-gray-400">
                                        <svg
                                          className="w-full h-10"
                                          xmlns="http://www.w3.org/2000/svg"
                                          x="0"
                                          y="0"
                                          version="1.1"
                                          viewBox="0 0 53 100"
                                          xmlSpace="preserve"
                                        >
                                          <circle cx="6" cy="50" r="6" fill="currentColor">
                                            <animate
                                              attributeName="opacity"
                                              begin="0.1"
                                              dur="1s"
                                              repeatCount="indefinite"
                                              values="0;1;0"
                                            ></animate>
                                          </circle>
                                          <circle cx="26" cy="50" r="6" fill="currentColor">
                                            <animate
                                              attributeName="opacity"
                                              begin="0.2"
                                              dur="1s"
                                              repeatCount="indefinite"
                                              values="0;1;0"
                                            ></animate>
                                          </circle>
                                          <circle cx="46" cy="50" r="6" fill="currentColor">
                                            <animate
                                              attributeName="opacity"
                                              begin="0.3"
                                              dur="1s"
                                              repeatCount="indefinite"
                                              values="0;1;0"
                                            ></animate>
                                          </circle>
                                        </svg>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                            {
                              session && session.voteResult === null && (
                                <p className="font-light text-gray-400">Waiting for votes</p>
                              )
                            }
                          </div>
                        )
                      }
                      {
                        session && session.votes.length === 0 && session.voteResult && (
                          <div>
                            <img
                              src="/Note_taking_Isometric.svg"
                              alt="No votes"
                              className="w-5/12 m-auto"
                            />
                            <p className="p-6 font-light text-gray-400">No one has voted</p>
                          </div>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Session
