import React, { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';

const GettingStarted = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <div className="leading-normal tracking-normal text-white bg-deep-purple">
        <div className="relative xl:container mx-auto px-3 pt-4 mb-4 flex flex-wrap flex-row items-center">
          <a className="uppercase tracking-loose" href="/">Estimation Poker</a>
          <a
            className="hidden md:block absolute right-0 mt-3 mr-4 pr-3 bg-brilliant-pink hover:bg-brilliant-pink-600 focus:bg-brilliant-pink-600 text-white rounded px-2 pb-1 pt-0 font-bold"
            href="https://ko-fi.com/V7V03TS2L"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="inline w-5 animate-wiggle"
              src="/cup-border.webp"
              alt="Buy Me a Coffee at ko-fi.com"
            />
            <span className="inline ml-2 text-xs">Buy me a coffee</span>
          </a>
        </div>

        <div className="relative">
          <svg viewBox="0 0 1428 174" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-2.000000, 44.000000)" fill="#FFFFFF" fillRule="nonzero">
                <path d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496" opacity="0.100000001"></path>
                <path
                  d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                  opacity="0.100000001"
                ></path>
                <path d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z" id="Path-4" opacity="0.200000003"></path>
              </g>
              <g transform="translate(-4.000000, 76.000000)" fill="#FFFFFF" fillRule="nonzero">
                <path
                  d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z"
                ></path>
              </g>
            </g>
          </svg>
        </div>
      </div>

      <section className="bg-white border-b py-8">
        <div className="container max-w-5xl mx-auto m-8">
          <h1 className="w-full my-2 pb-6 text-5xl font-bold leading-tight text-center text-gray-800">Getting <span className="text-brilliant-pink">started</span> with Estimation Poker <span role="img" aria-label="card">🃏</span></h1>

          <div className="flex flex-wrap">
            <div className="w-full p-6 mx-auto md:w-4/6">
              <p className="text-gray-600 mb-4">Estimation Poker allows teams to estimate user stories virtually online while trying to be as simple and easy to use as possible.</p>
              <p className="text-gray-600 mb-4">A new session can be started with just a few clicks.</p>
              <p className="text-gray-600 mb-4"><img className="w-2/3 mx-auto" src="/ep/estimationpoker-3.png" alt="Estimation Poker" /></p>
              <p className="text-gray-600 mb-4">Just enter your name and select a voting scheme to use. If you only want to observe the votes and not participate then you can check the observer checkbox.</p>
              <p className="text-gray-600 mb-4">Once the session is started you can share it with your team.</p>
              <p className="text-gray-600 mb-4">They’ll be able to enter their name and join the session.</p>
              <p className="text-gray-600 mb-4"><img className="w-2/3 mx-auto" src="/ep/estimationpoker-4.png" alt="Estimation Poker" /></p>
              <p className="text-gray-600 mb-4">When the team has joined, anyone can start a round of voting, when all the votes are cast then the results will be displayed.</p>
              <p className="text-gray-600 mb-4"><img className="w-2/3 mx-auto" src="/ep/estimationpoker-5.png" alt="Estimation Poker" /></p>
              <p className="text-gray-600 mb-4"><img className="w-2/3 mx-auto" src="/ep/estimationpoker-6.png" alt="Estimation Poker" /></p>
              <p className="text-gray-600 mb-4">Now the user story can be story pointed and the next round of voting can take place.</p>
              <p className="text-gray-600 mb-4"><Link className="text-brilliant-pink" to="/">Home</Link></p>
            </div>
          </div>

        </div>
      </section>
      <footer className="bg-white">
        <div className="container mx-auto px-8">
          <div className="w-full flex flex-col md:flex-row py-6">
            <div className="flex-1">
              <p className="uppercase text-gray-500 md:mb-6">Contact</p>
              <ul className="list-reset mb-6">
                <li className="mt-2 inline-block mr-2 md:block md:mr-0 text-sm">
                  Feedback, thoughts or suggestions? Feel free to shoot us an
                  <a href="mailto:info@estimationpoker.cards" className="no-underline text-brilliant-pink hover:text-brilliant-pink-600"> email</a>
                </li>
              </ul>
            </div>
            <div className="flex items-center md:justify-center">
              <ul className="list-reset">
                <li className="mt-2 inline-block mr-2 md:block md:mr-0 text-sm">
                  <a href="https://colinmc.dev/" target="_blank" rel="noreferrer" className="no-underline text-gray-800 hover:text-brilliant-pink-600">
                    <svg
                      className="inline w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                    </svg>
                    <span className="inline"> colinmc.dev</span>
                  </a>
                </li>
                <li className="mt-2 inline-block mr-2 md:block md:mr-0 text-sm">
                  <a href="https://twitter.com/col__mc" target="_blank" rel="noreferrer" className="no-underline text-gray-800 hover:text-brilliant-pink-600">
                    <svg
                      className="inline w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path
                        style={{ fill: '#1DA1F2', fillOpacity: 1 }}
                        id="path833"
                        d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-.139 9.237c.209 4.617-3.234 9.765-9.33 9.765-1.854 0-3.579-.543-5.032-1.475 1.742.205 3.48-.278 4.86-1.359-1.437-.027-2.649-.976-3.066-2.28.515.098 1.021.069 1.482-.056-1.579-.317-2.668-1.739-2.633-3.26.442.246.949.394 1.486.411-1.461-.977-1.875-2.907-1.016-4.383 1.619 1.986 4.038 3.293 6.766 3.43-.479-2.053 1.08-4.03 3.199-4.03.943 0 1.797.398 2.395 1.037.748-.147 1.451-.42 2.086-.796-.246.767-.766 1.41-1.443 1.816.664-.08 1.297-.256 1.885-.517-.439.656-.996 1.234-1.639 1.697z" />
                    </svg>
                    <span className="inline"> @col__mc</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default GettingStarted
