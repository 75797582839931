import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ky from 'ky';
import getColour from '../utils/colours';

const Join = (props) => {
  const [name, setName] = useState('');
  const [observer, setObserver] = useState(false);
  const [session, setSession] = useState('');
  const history = useHistory();

  useEffect(() => {
    if (props.match.params.id) {
      setSession(props.match.params.id);
    }
  }, [props.match.params.id]);

  useEffect(() => {
    setName(window.localStorage.getItem('name') ?? '');
  }, []);

  useEffect(() => {
    window.localStorage.setItem('observer', observer);
  }, [observer]);

  async function joinSession(e) {
    e.preventDefault();

    const sessionId = await ky.post('/api/session/join', {
      json: {
        name: name,
        sessionId: session,
      }
    }).json();

    window.localStorage.setItem('name', name);
    window.localStorage.setItem('colour', getColour());
    history.push(`/session/${sessionId}`)
  }

  return (
    <>
      <Helmet>
        <title>Estimation Poker | Join a session</title>
      </Helmet>
      <div className="min-w-screen min-h-screen bg-deep-purple flex items-center justify-center px-5 pb-10 pt-16">
        <div className="w-full mx-auto rounded-lg bg-white shadow-lg p-5 text-gray-700" style={{ maxWidth: '400px' }}>
          <div className="mb-10">
            <h1 className="text-center font-light text-lg uppercase">Join estimation session</h1>
          </div>

          <form onSubmit={joinSession}>
            <div className="mb-3">
              <label className="uppercase text-gray-500 mb-3 text-xs font-semibold">Session Id</label>
              <div>
                <input
                  onChange={e => setSession(e.target.value)}
                  value={session}
                  className="w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:ring-0 focus:border-brilliant-pink transition-colors"
                  placeholder="e.g. nDgWFMHj"
                  type="text"
                  required
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="uppercase text-gray-500 mb-3 text-xs font-semibold">Name</label>
              <div>
                <input
                  onChange={e => setName(e.target.value)}
                  value={name}
                  className="w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:ring-0 focus:border-brilliant-pink transition-colors"
                  placeholder="John Smith"
                  type="text"
                  required
                />
              </div>
            </div>

            <label className="inline-flex items-center mt-2 mb-5">
              <input type="checkbox" className="h-5 w-5 text-brilliant-pink border-2 border-gray-200 rounded-md focus:outline-none focus:ring-0" checked={observer} onChange={() => setObserver(!observer)} />
              <span className="ml-2 uppercase text-gray-500 text-xs font-semibold">I'm an observer</span>
            </label>

            <div>
              <button type="submit" className="block w-full max-w-xs mx-auto bg-brilliant-pink hover:bg-brilliant-pink-600 focus:bg-brilliant-pink-600 text-white rounded-lg px-3 py-3 font-semibold">
                Join
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default Join
