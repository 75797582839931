import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ky from 'ky';
import getColour from '../utils/colours';

const cards = [
  { name: 'Zero', value: 0 },
  { name: 'Half', value: 0.5 },
  { name: 'One', value: 1 },
  { name: 'Two', value: 2 },
  { name: 'Three', value: 3 },
  { name: 'Five', value: 5 },
  { name: 'Eight', value: 8 },
  { name: 'Thirteen', value: 13 },
  { name: 'Twenty', value: 20 },
  { name: 'TwentyOne', value: 21 },
  { name: 'ThirtyFour', value: 34 },
  { name: 'Forty', value: 40 },
  { name: 'FiftyFive', value: 55 },
  { name: 'EightyNine', value: 89 },
  { name: 'Hundred', value: 100 },
  { name: 'XXS', value: null },
  { name: 'XS', value: null },
  { name: 'S', value: null },
  { name: 'M', value: null },
  { name: 'L', value: null },
  { name: 'XL', value: null },
  { name: 'XXL', value: null },
  { name: '?', value: null },
  { name: '∞', value: null },
  { name: 'Coffee', value: null },
];

const decks = [
  ['One', 'Two', 'Three', 'Five', 'Eight', 'Thirteen', '?', '∞', 'Coffee'],
  ['Zero', 'Half', 'One', 'Two', 'Three', 'Five', 'Eight', 'Thirteen', 'Twenty', 'Forty', 'Hundred', '?', '∞', 'Coffee'],
  ['Zero', 'One', 'Two', 'Three', 'Five', 'Eight', 'Thirteen', 'TwentyOne', 'ThirtyFour', 'FiftyFive', 'EightyNine', '?', '∞', 'Coffee'],
  ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', '?', '∞', 'Coffee'],
];

const Start = () => {
  const [name, setName] = useState('');
  const [deck, setDeck] = useState(decks[0]);
  const [observer, setObserver] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setName(window.localStorage.getItem('name') ?? '');
  }, []);

  useEffect(() => {
    window.localStorage.setItem('observer', observer);
  }, [observer]);

  async function createSession(e) {
    e.preventDefault();

    const sessionId = await ky.post('/api/session/start', {
      json: {
        name: name,
        deck: deck,
      }
    }).json();

    window.localStorage.setItem('name', name);
    window.localStorage.setItem('colour', getColour());
    history.push(`/session/${sessionId}`);
  }

  return (
    <>
      <Helmet>
        <title>Estimation Poker | Start a session</title>
      </Helmet>
      <div className="min-w-screen min-h-screen bg-deep-purple flex items-center justify-center px-5 pb-10 pt-16">
        <div className="w-full mx-auto rounded-lg bg-white shadow-lg p-5 text-gray-700" style={{ maxWidth: '500px' }}>
          <div className="mb-10">
            <h1 className="text-center font-light text-lg uppercase">Start estimation session</h1>
          </div>

          <form onSubmit={createSession}>

            <div className="mb-3">
              <label className="uppercase text-gray-500 mb-3 text-xs font-semibold">Name</label>
              <div>
                <input
                  onChange={e => setName(e.target.value)}
                  value={name}
                  className="w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:ring-0 focus:border-brilliant-pink transition-colors"
                  placeholder="John Smith"
                  type="text"
                  required
                />
              </div>
            </div>
            <p className="uppercase text-gray-500 mb-3 text-xs font-semibold">Choose your deck</p>
            <div className="h-72 overflow-auto pl-2 pt-2 pr-2 mb-4 border-2 border-gray-200 rounded-md scrollbar">
              {
                decks.map((d, index) => (
                  <div key={index} className="mb-3 flex -mx-2">
                    <div className="px-2 w-full">
                      <label htmlFor={d} className="flex items-center cursor-pointer">
                        <input onChange={() => setDeck(d)} type="radio" className="hidden" name="type" id={d} defaultChecked={index === 0} />
                        <div className={`w-full text-center pt-4 rounded-md border ${d === deck ? 'border-brilliant-pink' : ''}`}>
                          {
                            d.map(card => {
                              const c = cards.find(c => c.name === card);

                              return (
                                <div
                                  key={c.name}
                                  className={`inline-flex align-top mx-2 mb-4 bg-white rounded-sm shadow`}
                                >
                                  <div className="h-10 w-8 border-t-2 border-brilliant-pink rounded-sm flex justify-items items-center">
                                    <div className="w-full text-center">
                                      {
                                        c.name === 'Coffee' && (
                                          <div className="m-2 text-brilliant-pink">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140" fill="currentColor">
                                              <path d="M117.199 127.142H13.731a2.098 2.098 0 00-2.068 1.795 2.09 2.09 0 001.483 2.303L42.703 140a1.97 1.97 0 00.585.098h44.345c.195 0 .4-.039.586-.098l29.557-8.76a2.09 2.09 0 001.482-2.303 2.082 2.082 0 00-2.059-1.795zm-11.813-66.98l.653-11.457a2.06 2.06 0 00-.565-1.561 2.087 2.087 0 00-1.521-.659H26.978a2.11 2.11 0 00-1.521.649 2.1 2.1 0 00-.566 1.561l4.185 73.198a2.08 2.08 0 002.087 1.971h68.614a2.08 2.08 0 002.088-1.971l.673-11.695 1.425.068c13.51 0 24.493-11.257 24.493-25.089 0-13.339-10.233-24.254-23.07-25.015zm-66.068 56.387h-.205a3.124 3.124 0 01-3.122-2.946l-3.492-56.47a3.156 3.156 0 012.946-3.321c1.795-.132 3.219 1.205 3.326 2.936l3.492 56.464c.098 1.737-1.219 3.23-2.945 3.337zm64.635-14.632c-.322 0-.625-.078-.947-.098l1.893-33.269c8.448.521 15.188 7.745 15.188 16.627-.001 9.229-7.239 16.74-16.134 16.74zM40.361 29.44c1.405 6.35 8.262 9.345 9.042 9.667l2.39-5.805c-1.19-.487-4.711-2.497-5.307-5.214-.41-1.858.566-4.087 2.907-6.604 3.854-4.175 5.346-8.277 4.419-12.228C52.36 3.058 45.57.288 44.8 0l-2.244 5.853c.039.02 4.438 1.829 5.141 4.823.517 2.239-1.297 4.785-2.917 6.546-3.804 4.126-5.297 8.228-4.419 12.218zm16.729 0c1.405 6.35 8.263 9.345 9.043 9.667l2.39-5.805c-1.18-.487-4.692-2.487-5.297-5.214-.419-1.858.566-4.087 2.897-6.604 3.853-4.175 5.346-8.277 4.419-12.228C69.089 3.058 62.299.288 61.519 0l-2.233 5.853c.039.02 4.438 1.829 5.141 4.823.517 2.239-1.298 4.785-2.917 6.546-3.805 4.126-5.298 8.228-4.42 12.218zm16.74 0c1.395 6.35 8.252 9.345 9.033 9.667l2.39-5.805c-1.181-.487-4.692-2.487-5.297-5.214-.419-1.858.565-4.087 2.897-6.604 3.862-4.175 5.346-8.277 4.419-12.228C85.818 3.058 79.019.288 78.249 0l-2.233 5.853c.039.02 4.447 1.829 5.141 4.823.526 2.239-1.298 4.785-2.917 6.546-3.805 4.126-5.288 8.228-4.41 12.218z"></path>
                                            </svg>
                                          </div>
                                        )
                                      }
                                      {
                                        c.name !== 'Coffee' && (
                                          <h3 className="text-xs text-brilliant-pink">{c.value !== null ? c.value.toString().replace('0.5', '½') : c.name}</h3>
                                        )
                                      }
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div>
                      </label>
                    </div>
                  </div>
                ))
              }
            </div>

            <label className="inline-flex items-center mt-2 mb-5">
              <input type="checkbox" className="h-5 w-5 text-brilliant-pink border-2 border-gray-200 rounded-md focus:outline-none focus:ring-0" checked={observer} onChange={() => setObserver(!observer)} />
              <span className="ml-2 uppercase text-gray-500 text-xs font-semibold">I'm an observer</span>
            </label>

            <div>
              <button type="submit" className="block w-full max-w-xs mx-auto bg-brilliant-pink hover:bg-brilliant-pink-600 focus:bg-brilliant-pink-600 text-white rounded-lg px-3 py-3 font-semibold">
                Start
            </button>
            </div>
          </form>

        </div>
      </div>
    </>
  )
}

export default Start
